<template>
    <referenceren></referenceren>
  </template>
  
  <script>
  import Referenceren from "@/components/ecologist/reports/Referenceren/Referenceren";
  export default {
    name: "ReferencerenView",
    components: { Referenceren },
  };
  </script>
  
  <style scoped></style>
  