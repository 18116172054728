<template>
    <div>
      <h2>Справка для расчета экологического налога</h2>
      <el-form
        :model="referenceren"
        ref="formedForm"
        label-position="top"
        label-width="120px"
      >  
        <el-form-item label="Год">
          <el-row :gutter="10">
            <el-col :span="6">
              <el-date-picker
                style="width: 100%"
                value-format="yyyy"
                v-model="reportForm.year"
                type="year"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-form-item>
  
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item
              prop="organizationInfo"
              label="Квартал"
            >
              <el-select
              @change="changeOrg"
                style="width: 100%"
                clearable
                value-key="id"
                v-model="reportForm.quarter"
                filterable
                placeholder="Выберите"
              >
                <el-option
                  v-for="item in quarter"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
  
        <el-row>
          <el-col :span="4">
            <el-row>
            <el-form-item label="Тип файла">
              <el-select
                clearable
                v-model="reportForm.fileType"
                placeholder="Формат отчета"
              >
                <el-option
                  v-for="item in fileTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            </el-row>
            <el-row>
              <el-button :disabled="false" @click="downloadReport('Referenceren')" type="primary" style="margin-left: 10px;"
              >Сформировать отчет</el-button>
            </el-row>
            <br>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import Cookies from "js-cookie";
  
  export default {
    name: "Referenceren",
    data() {
      return {
        role: Cookies.get('role'),
        Referenceren:null,
        props: {
          id: "id",
          label: "name",
          children: "zones",
        },
        quarter:[
          {id:1,name:"Первый квартал"},
          {id:2,name:"Второй квартал"},
          {id:3,name:"Третий квартал"},
          {id:4,name:"Четвертый квартал"},
        ],
        reportForm: {
          quarter: null,
          year: null,
          fileType: null,
        },
  
        fileTypes: [
          {
            value: ".pdf",
            label: ".pdf",
          },
          {
            value: ".xls",
            label: ".xls",
          },
        ],
      };
    },
  
    computed: {
      ...mapGetters({
        organizationHierarchyByType: "GETORGANIZATIHIERARCHYBYTYPE",
        listDocumentNumbers: "GETLISTDOCUMENTNUMBERS",
        listResponsiblePersonForSelect: "GETLISTRESPONSIBLEPERSONFORSEARCH",
      }),
    },
  
    methods: {
      changeOrg() {
        this.getListResponsiblePersonForSelect();
      }, 
  
      handleCheckChange(data, checked, indeterminate) {
        console.log(data, checked, indeterminate);
      },
  
      loadNode(node, resolve) {
        if (node.level === 0) {
          return resolve([
            { id: 0, name: "Root1" },
            { id: 0, name: "Root2" },
          ]);
        }
        console.log(node);
        console.log(resolve);
      },
  
      downloadReport(NAME) {
        let data = {
            quarter: this.reportForm.quarter,
            year: this.reportForm.year,
            fileType: this.reportForm.fileType,
            reportName:NAME,
        };
        this.$store.dispatch("downloadAnyReports", data);
      },
  
      searchDocumentNumber(query) {
        this.$store.dispatch("getDocsNumbersSearch", { query: query });
      },
  
      getOrganizationHierarchyByType() {
        this.$store.dispatch("getOrganizationHierarchyByType", {
          type: 3,
        });
      },
  
      getListResponsiblePersonForSelect() {
         console.log(this.reportForm.organizationUnit);
        let units = [];
        if (this.reportForm.organizationUnit !== null) {
          units.push(this.reportForm.organizationUnit.id);
        }
        this.$store.dispatch("getListResponsiblePersonForSelect", units);
      },
    },
  
    mounted() {
      this.getOrganizationHierarchyByType();
      this.searchDocumentNumber("");
      this.getListResponsiblePersonForSelect(-1);
    },
  };
  </script>
  
  <style scoped></style>
  